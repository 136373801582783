import { Injectable } from '@angular/core'
import { Observable, pipe } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable()
export class ItemDetailService {
  serverAddress = environment.item_master + '/detail';
  constructor(private http: HttpClient) {
  }
  setUserAuth() {
    let token = localStorage.getItem("token")
    const headers = new HttpHeaders()
      .set('Authorization', token)
    return headers
  }
  getItemInfo(id) {
    return this.http.post<any>(this.serverAddress + '/getItemInfo', id, { headers: this.setUserAuth() })
  }
}

