import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { itemService } from '../../../helpers/services/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface data {
  value: string;
}
/**
 * rfq-datepicker
 * created for apply filter on each column
 */

@Component({
  selector: 'imageEditor',
  templateUrl: 'imageEditor.component.html',
  styleUrls: ['imageEditor.component.scss'],

})
export class imageEditorComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose Picture';
  src: any;
  events: any
  uploadedFiles: Array<File>;
  uploading = false;
  constructor(public dialogRef: MatDialogRef<imageEditorComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private itemService: itemService, private _snackBar: MatSnackBar) {

  }
  ngOnInit() {
    this.src = this.data
    console.log(this.data)
  }

  ngAfterViewInit() {

  }
  onFullSizeBtn() {
    var image = new Image();
    image.src = this.src.FullSizePicture
    var w = window.open("");
    w.document.write(image.outerHTML);
  }

  

}