import { Injectable } from '@angular/core'
import { Observable, pipe } from 'rxjs'


@Injectable()
export class rfqEquation {
  constructor() {
  }
  setGenFobMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_fob_margin') {
        params.newValue = params.data[this.getMasterFiledName('gen_fob_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_fob_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let cost_incl_agent_comm = this.getCostInclAgentComm(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)]);
      let value = this.getSellPrice(cost_incl_agent_comm, deduction)
      params.data[this.getMasterFiledName('gen_fob_sell_price', colHeaders)] = value;
    }

    params.data['rfqItem_gen_fob_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('gen_fob_margin', colHeaders)] = params.newValue;
  }

  setGenFobSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_fob_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('gen_fob_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_fob_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2)
      let fob_total_cost = this.getFobTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)])
      let value = this.getMargin(netsellPrice, fob_total_cost, params.newValue);
      params.data[this.getMasterFiledName('gen_fob_margin', colHeaders)] = value;
    }
    params.data['rfqItem_gen_fob_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('gen_fob_sell_price', colHeaders)] = params.newValue;
  }

  setGenMddpMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_mddp_margin') {
        params.newValue = params.data[this.getMasterFiledName('gen_mddp_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_mddp_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let mddp_total_cost = this.getMddpTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data.duty_rate_dollar_amount)
      let value = this.getSellPrice(mddp_total_cost, deduction)
      params.data[this.getMasterFiledName('gen_mddp_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_gen_mddp_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('gen_mddp_margin', colHeaders)] = params.newValue;
  }

  setGenMddpSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_mddp_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('gen_mddp_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_mddp_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let mddp_total_cost = this.getMddpTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data.duty_rate_dollar_amount)
      let value = this.getMargin(netsellPrice, mddp_total_cost, params.newValue);
      params.data[this.getMasterFiledName('gen_mddp_margin', colHeaders)] = value;
    }
    params.data['rfqItem_gen_mddp_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('gen_mddp_sell_price', colHeaders)] = params.newValue;
  }

  setGenPoeMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_poe_margin') {
        params.newValue = params.data[this.getMasterFiledName('gen_poe_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_poe_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let poe_total_cost = this.getPoeTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data.duty_rate_dollar_amount)
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let value = this.getSellPrice(poe_total_cost, deduction)
      params.data[this.getMasterFiledName('gen_poe_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_gen_poe_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('gen_poe_margin', colHeaders)] = params.newValue;
  }
  setGenPoeSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_poe_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('gen_poe_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_poe_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let poe_total_cost = this.getPoeTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data.duty_rate_dollar_amount)
      let value = this.getMargin(netsellPrice, poe_total_cost, params.newValue);
      params.data[this.getMasterFiledName('gen_poe_margin', colHeaders)] = value;
    }
    params.data['rfqItem_gen_poe_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('gen_poe_sell_price', colHeaders)] = params.newValue;
  }

  setGenWhseMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_whse_margin') {
        params.newValue = params.data[this.getMasterFiledName('gen_whse_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_whse_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let whse_total_cost = this.getWhseTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data['whsecost'], params.data[this.getMasterFiledName('cbm_pc', colHeaders)], params.data.duty_rate_dollar_amount)
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let value = this.getSellPrice(whse_total_cost, deduction)
      params.data[this.getMasterFiledName('gen_whse_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_gen_whse_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('gen_whse_margin', colHeaders)] = params.newValue;
  }

  setGenWhseSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'gen_whse_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('gen_whse_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('gen_whse_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('gen_fob_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let whse_total_cost = this.getWhseTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data['whsecost'], params.data[this.getMasterFiledName('cbm_pc', colHeaders)], params.data.duty_rate_dollar_amount)
      let value = this.getMargin(netsellPrice, whse_total_cost, params.newValue);
      params.data[this.getMasterFiledName('gen_whse_margin', colHeaders)] = value;
    }
    params.data['rfqItem_gen_whse_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('gen_whse_sell_price', colHeaders)] = params.newValue;
  }

  setLicFobMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_fob_margin') {
        params.newValue = params.data[this.getMasterFiledName('lic_fob_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_fob_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let fob_total_cost = this.getFobTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)])
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_fob_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let value = this.getSellPrice(fob_total_cost, deduction)
      params.data[this.getMasterFiledName('lic_fob_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_lic_fob_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('lic_fob_margin', colHeaders)] = params.newValue;
  }

  setLicFobSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_fob_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('lic_fob_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_fob_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_fob_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let fob_total_cost = this.getFobTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)])
      let value = this.getMargin(netsellPrice, fob_total_cost, params.newValue);
      params.data[this.getMasterFiledName('lic_fob_margin', colHeaders)] = value;
    }
    params.data['rfqItem_lic_fob_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('lic_fob_sell_price', colHeaders)] = params.newValue;
  }

  setLicMddpMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_mddp_margin') {
        params.newValue = params.data[this.getMasterFiledName('lic_mddp_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_mddp_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let mddp_total_cost = this.getMddpTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data.duty_rate_dollar_amount)
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_mddp_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let value = this.getSellPrice(mddp_total_cost, deduction)
      params.data[this.getMasterFiledName('lic_mddp_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_lic_mddp_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('lic_mddp_margin', colHeaders)] = params.newValue;
  }

  setLicMddpSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_mddp_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('lic_mddp_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_mddp_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_mddp_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let mddp_total_cost = this.getMddpTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data.duty_rate_dollar_amount)
      let value = this.getMargin(netsellPrice, mddp_total_cost, params.newValue);
      params.data[this.getMasterFiledName('lic_mddp_margin', colHeaders)] = value;
    }
    params.data['rfqItem_lic_mddp_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('lic_mddp_sell_price', colHeaders)] = params.newValue;
  }

  setLicPoeMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_poe_margin') {
        params.newValue = params.data[this.getMasterFiledName('lic_poe_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_poe_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let poe_total_cost = this.getPoeTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data.duty_rate_dollar_amount)
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_poe_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let value = this.getSellPrice(poe_total_cost, deduction)
      params.data[this.getMasterFiledName('lic_poe_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_lic_poe_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('lic_poe_margin', colHeaders)] = params.newValue;
  }

  setLicPoeSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_poe_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('lic_poe_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_poe_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_poe_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let poe_total_cost = this.getPoeTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data.duty_rate_dollar_amount)
      let value = this.getMargin(netsellPrice, poe_total_cost, params.newValue);
      params.data[this.getMasterFiledName('lic_poe_margin', colHeaders)] = value;
    }
    params.data['rfqItem_lic_poe_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('lic_poe_sell_price', colHeaders)] = params.newValue;
  }

  setLicWhseMargin(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_whse_margin') {
        params.newValue = params.data[this.getMasterFiledName('lic_whse_margin', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_whse_margin', colHeaders)]
    }
    if (params.newValue) {
      let margin = (parseFloat(params.newValue))
      let whse_total_cost = this.getWhseTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data['whsecost'], params.data[this.getMasterFiledName('cbm_pc', colHeaders)], params.data.duty_rate_dollar_amount)
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_whse_royalty', colHeaders)], margin, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let value = this.getSellPrice(whse_total_cost, deduction)
      params.data[this.getMasterFiledName('lic_whse_sell_price', colHeaders)] = value;
    }
    params.data['rfqItem_lic_whse_entered_margin'] = params.newValue;
    params.data[this.getMasterFiledName('lic_whse_margin', colHeaders)] = params.newValue;
  }

  setLicWhseSellPrice(params, colHeaders) {
    if (params.column) {
      if (params.column.userProvidedColDef.col_id != 'lic_whse_sell_price') {
        params.newValue = params.data[this.getMasterFiledName('lic_whse_sell_price', colHeaders)]
      }
    } else {
      params.newValue = params.data[this.getMasterFiledName('lic_whse_sell_price', colHeaders)]
    }
    if (params.newValue) {
      let deduction = this.getDeduction(params.data[this.getMasterFiledName('lic_whse_royalty', colHeaders)], 0, params.data[this.getMasterFiledName('logistics_load', colHeaders)], parseFloat(params.data[this.getMasterFiledName('dilution', colHeaders)]))
      let netsellPrice = (parseFloat(params.newValue) * (parseFloat(deduction))).toFixed(2);
      let whse_total_cost = this.getWhseTotalCost(params.data[this.getMasterFiledName('fob_cost', colHeaders)], params.data[this.getMasterFiledName('agent', colHeaders)], params.data[this.getMasterFiledName('duty_rate', colHeaders)].title, params.data[this.getMasterFiledName('freight', colHeaders)], params.data['whsecost'], params.data[this.getMasterFiledName('cbm_pc', colHeaders)], params.data.duty_rate_dollar_amount)
      let value = this.getMargin(netsellPrice, whse_total_cost, params.newValue);
      params.data[this.getMasterFiledName('lic_whse_margin', colHeaders)] = value;
    }
    params.data['rfqItem_lic_whse_entered_sell_price'] = params.newValue;
    params.data[this.getMasterFiledName('lic_whse_sell_price', colHeaders)] = params.newValue;
  }

  getMasterFiledName(colId, colHeaders) {
    for (var i = 0; colHeaders.length > i; i++) {
      if (colHeaders[i].col_id == colId) {
        return colHeaders[i].field
      }
    }
  }

  getFobTotalCost(fobCost, AgentComm) {
    if (fobCost == null || fobCost == '' || fobCost == undefined || AgentComm == 'undefined') {
      fobCost = 0
    } else {
      fobCost = parseFloat(fobCost)
    }
    if (AgentComm == null || AgentComm == '' || AgentComm == undefined || AgentComm == 'undefined') {
      AgentComm = 0
    } else {
      AgentComm = parseFloat(AgentComm)
    }
    //factory price
    return fobCost * (1 + AgentComm / 100);
  }

  getMddpTotalCost(fobCost, AgentComm, dutyRate, dutyDollar) {
    if (fobCost == null || fobCost == '' || fobCost == undefined || fobCost == 'undefined') {
      fobCost = 0
    } else {
      fobCost = parseFloat(fobCost)
    }
    if (AgentComm == null || AgentComm == '' || AgentComm == undefined || AgentComm == 'undefined') {
      AgentComm = 0
    } else {
      AgentComm = parseFloat(AgentComm)
    }
    if (dutyRate == null || dutyRate == '' || dutyRate == undefined || dutyRate == 'undefined') {
      dutyRate = 0
    } else {
      dutyRate = parseFloat(dutyRate)
    }
    if (dutyDollar == null || dutyDollar == '' || dutyDollar == undefined || dutyDollar == 'undefined') {
      dutyDollar = 0
    } else {
      dutyDollar = parseFloat(dutyDollar)
    }
    let cost_incl_agent_comm = (fobCost * (1 + AgentComm / 100));
    let cost_incl_duty_agent = (cost_incl_agent_comm * (1 + (dutyRate / 100)) + dutyDollar).toFixed(7)
    
    return cost_incl_duty_agent;
  }

  getPoeTotalCost(fobCost, AgentComm, dutyRate, freight, dutyDollar) {
    if (fobCost == null || fobCost == '' || fobCost == undefined || fobCost == 'undefined') {
      fobCost = 0
    } else {
      fobCost = parseFloat(fobCost)
    }
    if (AgentComm == null || AgentComm == '' || AgentComm == undefined || AgentComm == 'undefined') {
      AgentComm = 0
    } else {
      AgentComm = parseFloat(AgentComm)
    }
    if (freight == null || freight == '' || freight == undefined || freight == 'undefined') {
      freight = 0
    } else {
      freight = parseFloat(freight)
    }
    if (dutyRate == null || dutyRate == '' || dutyRate == undefined || dutyRate == 'undefined') {
      dutyRate = 0
    } else {
      dutyRate = parseFloat(dutyRate)
    }
    if (dutyDollar == null || dutyDollar == '' || dutyDollar == undefined || dutyDollar == 'undefined') {
      dutyDollar = 0
    } else {
      dutyDollar = parseFloat(dutyDollar)
    }
    return ((fobCost * (1 + (AgentComm / 100))) * (1 + (dutyRate / 100)) + dutyDollar) + freight;
  }
  getWhseTotalCost(fobCost, AgentComm, dutyRate, freight, whsesCost, cbm_pc, dutyDollar) {
    if (fobCost == null || fobCost == '' || fobCost == undefined || fobCost == 'undefined') {
      fobCost = 0
    } else {
      fobCost = parseFloat(fobCost)
    }
    if (AgentComm == null || AgentComm == '' || AgentComm == undefined || AgentComm == 'undefined') {
      AgentComm = 0
    } else {
      AgentComm = parseFloat(AgentComm)
    }
    if (freight == null || freight == '' || freight == undefined || freight == 'undefined') {
      freight = 0
    } else {
      freight = parseFloat(freight)
    }
    if (dutyRate == null || dutyRate == '' || dutyRate == undefined || dutyRate == 'undefined') {
      dutyRate = 0
    } else {
      dutyRate = parseFloat(dutyRate)
    }
    if (whsesCost == null || whsesCost == '' || whsesCost == undefined || whsesCost == 'undefined') {
      whsesCost = 0
    } else {
      whsesCost = parseFloat(whsesCost)
    }
    if (cbm_pc == null || cbm_pc == '' || cbm_pc == undefined || cbm_pc == 'undefined') {
      cbm_pc = 0
    } else {
      cbm_pc = parseFloat(cbm_pc)
    }
    if (dutyDollar == null || dutyDollar == '' || dutyDollar == undefined || dutyDollar == 'undefined') {
      dutyDollar = 0
    } else {
      dutyDollar = parseFloat(dutyDollar)
    }
    let whse_total_cost = ((whsesCost / 56) * cbm_pc).toFixed(2);
    let costInclDutyAgent = ((fobCost * (1 + (AgentComm / 100))) * (1 + (dutyRate / 100)) + dutyDollar).toFixed(7);
    let ldp_port = (parseFloat(costInclDutyAgent) + parseFloat(freight)).toFixed(7);
    let ldp_whse = (parseFloat(whse_total_cost) + parseFloat(ldp_port)).toFixed(7);
    return ldp_whse;
  }

  getDeduction(royalty, margin, lgload, dilution) {
    if (royalty == null || royalty == '' || royalty == undefined || royalty == 'undefined') {
      royalty = 0
    } else {
      royalty = parseFloat(royalty)
    }
    if (margin == null || margin == '' || margin == undefined || margin == 'undefined') {
      margin = 0
    } else {
      margin = parseFloat(margin)
    }
    if (lgload == null || lgload == '' || lgload == undefined || lgload == 'undefined') {
      lgload = 0
    } else {
      lgload = parseFloat(lgload)
    }
    if (dilution == null || dilution == '' || dilution == undefined || dilution == 'undefined' || isNaN(dilution)) {
      dilution = 0
    } else {
      dilution = parseFloat(dilution)
    }
    let deduction = (1 - (royalty + margin + lgload + dilution) / 100).toFixed(4);
    return deduction.toString();
    //let deduction = (royalty + margin + lgload + dilution).toFixed(2);
    //return deduction;
  }

  getSellPrice(totalCost, deduction) {
    //totalcost is net buy price (vendor)
    if (totalCost == null || totalCost == '' || totalCost == undefined || totalCost == 'undefined') {
      totalCost = 0
    } else {
      totalCost = parseFloat(totalCost)
    }
    if (deduction == null || deduction == '' || deduction == undefined || deduction == 'undefined') {
      deduction = 0
    } else {
      deduction = parseFloat(deduction)
    }
    let sellPrice;
    return sellPrice = (totalCost / deduction).toFixed(2);

  }
  getMargin(netSellPrice, TotalCost, GrossSellPrice) {
    if (netSellPrice == null || netSellPrice == '' || netSellPrice == undefined || netSellPrice == 'undefined') {
      netSellPrice = 0
    } else {
      netSellPrice = parseFloat(netSellPrice)
    }
    if (TotalCost == null || TotalCost == '' || TotalCost == undefined || TotalCost == 'undefined') {
      TotalCost = 0
    } else {
      TotalCost = parseFloat(TotalCost)
    }
    if (GrossSellPrice == null || GrossSellPrice == '' || GrossSellPrice == undefined || GrossSellPrice == 'undefined') {
      GrossSellPrice = 0
    } else {
      GrossSellPrice = parseFloat(GrossSellPrice)
    }

    let margin;
    margin = ((netSellPrice - TotalCost) / GrossSellPrice) * 100
    return margin.toFixed(4);

  }

  getCostInclAgentComm(fobcost, agent) {
    if (fobcost == null || fobcost == '' || fobcost == undefined || fobcost == 'undefined') {
      fobcost = 0
    } else {
      fobcost = parseFloat(fobcost)
    }
    if (agent == null || agent == '' || agent == undefined || agent == 'undefined') {
      agent = 0
    } else {
      agent = parseFloat(agent)
    }
    return (parseFloat(fobcost) * (1 + (parseFloat(agent) / 100))).toFixed(2);
  }

  getCostInclDutyAgent(cost_incl_agent_comm, duty_percent, duty_rate_dollar_amount) {
    if (cost_incl_agent_comm == null || cost_incl_agent_comm == '' || cost_incl_agent_comm == undefined || cost_incl_agent_comm == 'undefined') {
      cost_incl_agent_comm = 0
    } else {
      cost_incl_agent_comm = parseFloat(cost_incl_agent_comm)
    }
    if (duty_percent == null || duty_percent == '' || duty_percent == undefined || duty_percent == 'undefined') {
      duty_percent = 0
    } else {
      duty_percent = parseFloat(duty_percent)
    }
    if (duty_rate_dollar_amount == null || duty_rate_dollar_amount == '' || duty_rate_dollar_amount == undefined || duty_rate_dollar_amount == 'undefined') {
      duty_rate_dollar_amount = 0
    } else {
      duty_rate_dollar_amount = parseFloat(duty_rate_dollar_amount)
    }
    return (parseFloat(cost_incl_agent_comm) * (1 + (parseFloat(duty_percent) / 100)) + duty_rate_dollar_amount).toFixed(2);
  }

  getWhseCost(cbm, whse) {
    if (cbm == null || cbm == '' || cbm == undefined || cbm == 'undefined') {
      cbm = 0
    } else {
      cbm = parseFloat(cbm)
    }
    if (whse == null || whse == '' || whse == undefined || whse == 'undefined') {
      whse = 0
    } else {
      whse = parseFloat(whse)
    }
    return (cbm * whse).toFixed(2);
  }

  getLdpPort(cost_incl_duty_agent, freight) {
    if (cost_incl_duty_agent == null || cost_incl_duty_agent == '' || cost_incl_duty_agent == undefined || cost_incl_duty_agent == 'undefined') {
      cost_incl_duty_agent = 0
    } else {
      cost_incl_duty_agent = parseFloat(cost_incl_duty_agent)
    }
    if (freight == null || freight == '' || freight == undefined || freight == 'undefined') {
      freight = 0
    } else {
      freight = parseFloat(freight)
    }
    return (cost_incl_duty_agent + freight).toFixed(2);
  }

  getLdpWhse(cost_incl_duty_agent, whse_cost, ldp_port) {
    if (cost_incl_duty_agent == null || cost_incl_duty_agent == '' || cost_incl_duty_agent == undefined || cost_incl_duty_agent == 'undefined') {
      cost_incl_duty_agent = 0
    } else {
      cost_incl_duty_agent = parseFloat(cost_incl_duty_agent)
    }
    if (whse_cost == null || whse_cost == '' || whse_cost == undefined || whse_cost == 'undefined') {
      whse_cost = 0
    } else {
      whse_cost = parseFloat(whse_cost)
    }
    if (ldp_port == null || ldp_port == '' || ldp_port == undefined || ldp_port == 'undefined') {
      ldp_port = 0
    } else {
      ldp_port = parseFloat(ldp_port)
    }
    return (cost_incl_duty_agent + whse_cost + ldp_port).toFixed(2);
  }

  async recalculate_Lic_Gen(params, colHeaders) {
    await this.setGenFobMargin(params, colHeaders);
    // this.setGenFobSellPrice(params, colHeaders);
    await this.setGenMddpMargin(params, colHeaders);
    // this.setGenMddpSellPrice(params, colHeaders);
    await this.setGenPoeMargin(params, colHeaders);
    // this.setGenPoeSellPrice(params, colHeaders);
    await this.setGenWhseMargin(params, colHeaders);
    //   this.setGenWhseSellPrice(params, colHeaders);
    await this.setLicFobMargin(params, colHeaders);
    //  this.setLicFobSellPrice(params, colHeaders);
    await this.setLicMddpMargin(params, colHeaders);
    //  this.setLicMddpSellPrice(params, colHeaders);
    await this.setLicPoeMargin(params, colHeaders);
    //  this.setLicPoeSellPrice(params, colHeaders);
    await this.setLicWhseMargin(params, colHeaders);
    //  this.setLicWhseSellPrice(params, colHeaders);
  }
}
