<div style="margin-top: 5%;">
  <mat-tab-group dynamicHeight mat-stretch-tabs style="margin-left: 1%;margin-right: 1%;">
    <mat-tab *ngFor="let tab of asyncTabs" [label]="tab.label">
      <div *ngIf="tab.id == '1'">
        <mat-card>
          <div *ngFor="let data of itemData" class="tracking">
            <mat-label>Style# :{{data.item_num_id}}</mat-label>
            <mat-label>Item ref# : {{data.dsn_ref_num}}</mat-label>
            <mat-label>Item description :{{data.item_displ_descr_name}}</mat-label>
            <mat-label>Licensing status :{{data.udf_freeform_01}}</mat-label>
            <mat-label>Production status :{{data.udf_freeform_02}}</mat-label>
          </div>
        </mat-card>
        <br />
        <mat-card>Tracking</mat-card>
        <br />
        <div>
          <div class="item-detail-card">
            <div class="image-viewer">
              <mat-card class="item-detail-img-card image-mat">
                <gallery gallerize [items]="GalleryItems" style="background-color:rgba(0, 0, 0, 0)"></gallery>
                <!--
                   <gallery gallerize [items]="GalleryItems" style="background-color:rgba(0, 0, 0, 0)"></gallery>
                  <splide [options]="{ autoHeight:true}" containerClass="customSplideClass">
                  <splide-slide *ngFor="let image of detailImages; let i=index">
                    <img #thumb style="cursor: zoom-in;" (click)="onThumb(i)" [src]="image.attachment_link" alt="" />
                  </splide-slide>

                </splide> -->


              </mat-card>
              <div style="margin-top: 5%;">
                <div *ngFor="let data of attachments">
                  <mat-card>{{data.attachment_display_name}}</mat-card>
                  <br />
                </div>
              </div>
            </div>
            <div style="width: 100%">
              <mat-card class="col-xxl-auto" style="width:100%;">
                <section *ngIf="!editing">
                  <div *ngFor="let data of itemData" class="item-detail-div">
                    <div style="width: 100%;position: absolute;">

                      <section style="display: flex;justify-content: space-evenly;margin-bottom: 5%;">
                        <mat-slide-toggle color="primary" [(ngModel)]="data.item_active_status">Active Status
                        </mat-slide-toggle>
                        <mat-slide-toggle color="primary" [(ngModel)]="data.discont_status">Discontinued status
                        </mat-slide-toggle>
                      </section>

                    </div>
                  </div>
                </section>
                <mat-tab-group *ngIf="editing" dynamicHeight mat-stretch-tabs>
                  <mat-tab label="First">

                    <div *ngFor="let data of itemData" class="item-detail-div">
                      <div style="width: 100%;position: absolute;">

                        <section style="display: flex;justify-content: space-evenly;margin-bottom: 5%;">
                          <mat-slide-toggle color="primary" [(ngModel)]="data.item_active_status">Active Status
                          </mat-slide-toggle>
                          <mat-slide-toggle color="primary" [(ngModel)]="data.discont_status">Discontinued status
                          </mat-slide-toggle>
                        </section>

                      </div>
                      <div class="item-detail-div-divider-firstTap item-detail-top-div">
                        <div class="item-detail-div-split">

                          <section class="item-detail-section">
                            <mat-label>Production status : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #item_depth_size matInput type="text" [(ngModel)]="data.item_depth_size">
                              <button *ngIf="item_depth_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="item_depth_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Weight size : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #item_weight_size matInput type="text" [(ngModel)]="data.item_weight_size">
                              <button *ngIf="item_weight_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="item_weight_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Note : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #item_note matInput type="text" [(ngModel)]="data.item_note">
                              <button *ngIf="item_note.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="item_note.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Content : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #item_content matInput type="text" [(ngModel)]="data.item_content">
                              <button *ngIf="item_content.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="item_content.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>IDK : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #item_cbm_size matInput type="text" [(ngModel)]="data.item_cbm_size">
                              <button *ngIf="item_cbm_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="item_cbm_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>

                        </div>
                      </div>
                      <div class="item-detail-div-divider-firstTap">
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Standard cost : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #item_cost_ext matInput type="text" [(ngModel)]="data.item_cost_ext">
                              <button *ngIf="item_cost_ext.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="item_cost_ext.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Material : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select  [(ngModel)]="selectedMaterial">
                                <mat-option *ngFor="let value of materialOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Construction : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedConstruction">
                                <mat-option *ngFor="let value of constructionOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Feature : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedFeature">
                                <mat-option *ngFor="let value of featureOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Size : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedSize">
                                <mat-option *ngFor="let value of sizeOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Licensor : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedLicensor">
                                <mat-option *ngFor="let value of licensorOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Property : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedProperty">
                                <mat-option *ngFor="let value of propertyOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>

                        </div>
                      </div>
                      <div class="item-detail-div-divider-firstTap">
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Format : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedFormat">
                                <mat-option *ngFor="let value of formatOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                
                          <section class="item-detail-section">
                            <mat-label>Brand assurance : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_freeform_03 matInput type="text" [(ngModel)]="data.udf_freeform_03">
                              <button *ngIf="udf_freeform_03.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_freeform_03.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Product manager : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select >
                                <mat-option *ngFor="let value of data.udf_freeform_04" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Cust designed : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.udf_freeform_05" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Art source : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedArtContent">
                                <mat-option *ngFor="let value of artContentOptions" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Style guide : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="selectedProperty">
                                <mat-option *ngFor="let value of data.udf_merchgroup11_fk" [value]="value">
                                  {{value.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Season : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #seasonCode matInput type="text" [(ngModel)]="data.season_code_fk">
                              <button *ngIf="seasonCode.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="seasonCode.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Retail price : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #retailPrice matInput type="text" [(ngModel)]="data.retail_price">
                              <button *ngIf="retailPrice.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="retailPrice.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Selling price : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #sellingPrice matInput type="text" [(ngModel)]="data.selling_price">
                              <button *ngIf="sellingPrice.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="sellingPrice.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>


                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Second">
                    <div *ngFor="let data of itemData" class="item-detail-div">
                      <div>
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Artist : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.udf_merchgroup09_fk" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Packaging type : </mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.udf_freeform_06" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Earliest on shelf : </mat-label>
                            <mat-form-field class="item-detail-input-section" appearance="outline">
                              <input matInput [matDatepicker]="udf_date01" [formControl]="dates[0]">
                              <mat-datepicker-toggle matSuffix [for]="udf_date01"></mat-datepicker-toggle>
                              <mat-datepicker #udf_date01></mat-datepicker>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Test report Recv date : </mat-label>
                            <mat-form-field class="item-detail-input-section" appearance="outline">
                              <input matInput [matDatepicker]="udf_date02" [formControl]="dates[1]">
                              <mat-datepicker-toggle matSuffix [for]="udf_date02"></mat-datepicker-toggle>
                              <mat-datepicker #udf_date02></mat-datepicker>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Photography Recv date : </mat-label>
                            <mat-form-field class="item-detail-input-section" appearance="outline">
                              <input matInput [matDatepicker]="udf_date03" [formControl]="dates[2]">
                              <mat-datepicker-toggle matSuffix [for]="udf_date03"></mat-datepicker-toggle>
                              <mat-datepicker #udf_date03></mat-datepicker>
                            </mat-form-field>
                          </section>

                        </div>
                      </div>
                      <div class="item-detail-div-divider">
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Vendor : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #vendor_code_fk matInput type="text" [(ngModel)]="data.vendor_code_fk">
                              <button *ngIf="vendor_code_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="vendor_code_fk.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Company Code : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #compan_code_fk matInput type="text" [(ngModel)]="data.compan_code_fk">
                              <button *ngIf="compan_code_fk && compan_code_fk.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="compan_code_fk.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Division Code : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #div_code_fk matInput type="text" [(ngModel)]="data.div_code_fk">
                              <button *ngIf="div_code_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="div_code_fk.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Item Price A : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceA matInput type="text" [(ngModel)]="data.udf_item_priceA">
                              <button *ngIf="udf_item_priceA.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceA.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Item Price B : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceB matInput type="text" [(ngModel)]="data.udf_item_priceB">
                              <button *ngIf="udf_item_priceB.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceB.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Item Price C : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceC matInput type="text" [(ngModel)]="data.udf_item_priceC">
                              <button *ngIf="udf_item_priceC.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceC.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Item Price E : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceE matInput type="text" [(ngModel)]="data.udf_item_priceE">
                              <button *ngIf="udf_item_priceE.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceE.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>

                        </div>
                      </div>
                      <div class="item-detail-div-divider">
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Item Price F : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceF matInput type="text" [(ngModel)]="data.udf_item_priceF">
                              <button *ngIf="udf_item_priceF.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceF.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Item Price G : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceG matInput type="text" [(ngModel)]="data.udf_item_priceG">
                              <button *ngIf="udf_item_priceG.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceG.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Item Price H : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_item_priceH matInput type="text" [(ngModel)]="data.udf_item_priceH">
                              <button *ngIf="udf_item_priceH.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_item_priceH.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>HTSNumber : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #hts_num_ext_fk matInput type="text" [(ngModel)]="data.hts_num_ext_fk">
                              <button *ngIf="hts_num_ext_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="hts_num_ext_fk.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>HTSNumber 2 : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #hts2_num_ext_fk matInput type="text" [(ngModel)]="data.hts2_num_ext_fk">
                              <button *ngIf="hts2_num_ext_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="hts2_num_ext_fk.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Inner Pack Qty : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #innerpack_qty matInput type="text" [(ngModel)]="data.innerpack_qty">
                              <button *ngIf="innerpack_qty.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="innerpack_qty.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Base Qty : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #base_qty matInput type="text" [(ngModel)]="data.base_qty">
                              <button *ngIf="base_qty.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="base_qty.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>

                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Third">
                    <div *ngFor="let data of itemData" class="item-detail-div">
                      <div>
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Carton Length : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #carton_length_size matInput type="text" [(ngModel)]="data.carton_length_size">
                              <button *ngIf="carton_length_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="carton_length_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Carton Width : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #carton_width_size matInput type="text" [(ngModel)]="data.carton_width_size">
                              <button *ngIf="carton_width_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="carton_width_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Carton Height : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #carton_depth_size matInput type="text" [(ngModel)]="data.carton_depth_size">
                              <button *ngIf="carton_depth_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="carton_depth_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Carton Weight : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #carton_weight_size matInput type="text" [(ngModel)]="data.carton_weight_size">
                              <button *ngIf="carton_weight_size.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="carton_weight_size.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Carton Code : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #carton_code_ext matInput type="text" [(ngModel)]="data.carton_code_ext">
                              <button *ngIf="carton_code_ext.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="carton_code_ext.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Carton Pack Type :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.carton_packtype_fk" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Carton Qty : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #carton_qty matInput type="text" [(ngModel)]="data.carton_qty">
                              <button *ngIf="carton_qty.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="carton_qty.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                        </div>
                      </div>
                      <div class="item-detail-div-divider">
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Origin Country :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.origin_country_fk" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>RoyaltyCode :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.royalty_code_fk" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Sales Person Code 1 :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of salesperOption" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Pack Type :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.pack_type" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Mold Paid Inv : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_freeform_07 matInput type="text" [(ngModel)]="data.udf_freeform_07">
                              <button *ngIf="udf_freeform_07.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_freeform_07.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Contr Smpl PO :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.udf_freeform_08" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Annu Smpl PO :</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select>
                                <mat-option *ngFor="let value of data.udf_freeform_09" [value]="value">
                                  {{value}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </section>
                        </div>
                      </div>
                      <div class="item-detail-div-divider">
                        <div class="item-detail-div-split">
                          <section class="item-detail-section">
                            <mat-label>Fty Sent for Testing : </mat-label>
                            <mat-form-field class="item-detail-input-section" appearance="outline">
                              <input matInput [matDatepicker]="udf_date05" [formControl]="dates[3]">
                              <mat-datepicker-toggle matSuffix [for]="udf_date05"></mat-datepicker-toggle>
                              <mat-datepicker #udf_date05></mat-datepicker>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>fty Sent for Photo : </mat-label>
                            <mat-form-field class="item-detail-input-section" appearance="outline">
                              <input matInput [matDatepicker]="udf_date06" [formControl]="dates[4]">
                              <mat-datepicker-toggle matSuffix [for]="udf_date06"></mat-datepicker-toggle>
                              <mat-datepicker #udf_date06></mat-datepicker>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>UDF Int 01 : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_int01 matInput type="text" [(ngModel)]="data.udf_int01">
                              <button *ngIf="udf_int01.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_int01.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>UDF Int 02 : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_int02 matInput type="text" [(ngModel)]="data.udf_int02">
                              <button *ngIf="udf_int02.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_int02.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>UDF Num 01 : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #udf_num01 matInput type="text" [(ngModel)]="data.udf_num01">
                              <button *ngIf="udf_num01.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="udf_num01.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>On Hand Min : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #OH_min_qty matInput type="text" [(ngModel)]="data.OH_min_qty">
                              <button *ngIf="OH_min_qty.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="OH_min_qty.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>On Hand Max : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #OH_max_qty matInput type="text" [(ngModel)]="data.OH_max_qty">
                              <button *ngIf="OH_max_qty.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="OH_max_qty.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                          <section class="item-detail-section">
                            <mat-label>Compare Price : </mat-label>
                            <mat-form-field appearance="outline" class="item-detail-input-section">
                              <input #compare_price matInput type="text" [(ngModel)]="data.compare_price">
                              <button *ngIf="compare_price.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="compare_price.value = ''">
                                <mat-icon>close</mat-icon>
                              </button>
                            </mat-form-field>
                          </section>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </mat-card>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="tab.id == '2'">
        <mat-card>Tracking</mat-card>
        <br />
        <mat-card>Options</mat-card>
        <br />
        <mat-card>Comments from licensor</mat-card>
        <br />
        <mat-card>Samples</mat-card>
      </div>
      <div *ngIf="tab.id == '3'">
        <mat-card>Tracking</mat-card>
        <br />
        <mat-card>Pricing</mat-card>
      </div>
      <div *ngIf="tab.id == '4'">
        <mat-card>Tracking</mat-card>
        <br />
        <mat-card>Production</mat-card>
      </div>
      <div style="display: flex;justify-content: center;" *ngIf="tab.id == '5'">
        <mat-card style="min-height:calc(65em - 170px);min-width: calc(100% - 12%);">

          <div class="chat">
            <div class="first messages">
              <div class="message last">
                <div>
                  <mat-label>
                    Miusov, as a man man of breeding and deilcacy, could not but feel some inwrd qualms, when he reached
                    the Father Superior's with Ivan: he felt ashamed of havin lost his temper. He felt that he ought to
                    have disdaimed that despicable wretch, Fyodor Pavlovitch, too much to have been upset by him in
                    Father
                    Zossima's cell, and so to have forgotten himself. "Teh monks were not to blame, in any case," he
                    reflceted, on the steps. "And if they're decent people here (and the Father Superior, I understand,
                    is
                    a nobleman) why not be friendly and courteous withthem? I won't argue, I'll fall in with everything,
                    I'll win them by politness, and show them that I've nothing to do with that Aesop, thta buffoon,
                    that
                    Pierrot, and have merely been takken in over this affair, just as they have."
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="second messages">
              <div class="message">
                Hey!
              </div>
              <div class="message">
                You there?
              </div>
              <div class="message last">
                Hello, how's it going?
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="second-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="first messages">
              <div class="message">
                Hello!
              </div>
              <div class="message">
                Great thanks!
              </div>
              <div class="message last">
                <div>
                  <mat-label>
                    How about you?
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
          </div>

        </mat-card>
      </div>
      <div style="display: flex;justify-content: center;" *ngIf="tab.id == '6'">
        <mat-card style="min-height:calc(65em - 170px);min-width: calc(100% - 12%);">

          <div class="chat">
            <div class="first messages">
              <div class="message last">
                <div>
                  <mat-label>
                    Miusov, as a man man of breeding and deilcacy, could not but feel some inwrd qualms, when he reached
                    the Father Superior's with Ivan: he felt ashamed of havin lost his temper. He felt that he ought to
                    have disdaimed that despicable wretch, Fyodor Pavlovitch, too much to have been upset by him in
                    Father
                    Zossima's cell, and so to have forgotten himself. "Teh monks were not to blame, in any case," he
                    reflceted, on the steps. "And if they're decent people here (and the Father Superior, I understand,
                    is
                    a nobleman) why not be friendly and courteous withthem? I won't argue, I'll fall in with everything,
                    I'll win them by politness, and show them that I've nothing to do with that Aesop, thta buffoon,
                    that
                    Pierrot, and have merely been takken in over this affair, just as they have."
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="second messages">
              <div class="message">
                Hey!
              </div>
              <div class="message">
                You there?
              </div>
              <div class="message last">
                Hello, how's it going?
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="second-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="first messages">
              <div class="message">
                Hello!
              </div>
              <div class="message">
                Great thanks!
              </div>
              <div class="message last">
                <div>
                  <mat-label>
                    How about you?
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
          </div>

        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>