<div>
            <br />
            <div style="text-align: -webkit-center;">
                <div>
                    <img style="max-width: calc(100% - 30%);max-height: calc(700px - 50px);"
                        [src]="src.FullSizePicture">
                </div>
                <br />
                <button mat-raised-button color="primary" style="margin-right: 1%" (click)="onFullSizeBtn()">Open
                    Picture in
                    a new tab</button>
            </div>
 
</div>