import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { CommonModule } from '@angular/common';
import { HotTableModule } from '@handsontable/angular';
import { helpersModule } from './helpers/helpersModule.module'
import { itemService } from './helpers/services/main.service'
import { rfqEquation } from './pages/rfq/helpers/equation'
import { prodEquation } from './pages/prod_tracking/helpers/equation'
import { licensingEquation } from './pages/licensing_tracking/helpers/equation'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService } from './pages/auth/helpers/auth.guard';
import { RoleGuardService } from './pages/auth/helpers/role-guard.service';
import { AuthService } from './pages/auth/helpers/auth.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpErrorInterceptor } from './pages/auth/helpers//http-error.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NotificationsService } from 'angular2-notifications';
import { UserAuthInterceptor } from './helpers/interceptor/user-auth.interceptor';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { ItemDetailService } from './helpers/services/item_detail.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
@NgModule({
  declarations: [AppComponent],
  imports: [

    SimpleNotificationsModule.forRoot({
      position: ["bottom", "right"],
      timeOut: 5000,
      lastOnBottom: true,
      clickToClose: true,
    }),
    MatDatepickerModule,
    MatSnackBarModule,
    HotTableModule,
    CommonModule,
    AppRoutingModule,
    helpersModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Vex
    VexModule,
    CustomLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule
  ],

  providers: [itemService, ItemDetailService, prodEquation,licensingEquation, rfqEquation, AuthGuardService, RoleGuardService, AuthService, NotificationsService, {
    useClass: HttpErrorInterceptor,
    multi: true,
    provide: HTTP_INTERCEPTORS,
  },
    {
      useClass: UserAuthInterceptor,
      multi: true,
      provide: HTTP_INTERCEPTORS,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
