import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { carouselImage } from '../../helpers/models/carouselImage.module'
import { itemService } from '../../helpers/services/main.service'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { dateFormat } from '../../helpers/models/dateFormat.module'
import { FormControl } from '@angular/forms';
import { GalleryItem, ImageItem, Gallery } from 'ng-gallery';
import { ItemDetailService } from '../../helpers/services/item_detail.service'
import { imageEditorComponent } from '../itemLibrary/imageEditor/imageEditor.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-itemDetail',
  templateUrl: './itemDetail.component.html',
  styleUrls: ['./itemDetail.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: dateFormat },
  ]
})

export class ItemDetailComponent implements AfterViewInit {
  editing: boolean = true;
  selectedMaterial: any = []
  selectedConstruction: any = []
  selectedFeature: any = []
  selectedSize: any = []
  selectedLicensor: any = []
  selectedProperty: any = []
  selectedFormat: any = []
  selectedArtContent: any = []
  materialOptions: any = []
  constructionOptions: any = []
  featureOptions: any = []
  sizeOptions: any = []
  licensorOptions: any = []
  propertyOptions: any = []
  formatOptions: any = []
  artContentOptions: any = []
  galleryId = 'myLightbox';
  GalleryItems: GalleryItem[] = []
  detailImages: any[] = []
  detailThumbs: any[] = [];
  asyncTabs: any = []
  attachments: any = [];
  itemData: any = [];
  dates: any = [];
  UDFElement: any = []
  salesperOption: any = []
  carton_qty
  UDFElementGroup: any = []
  mg: any = [];
  selected = false
  constructor(public dialog: MatDialog, private itemService: itemService, public gallery: Gallery, public ItemDetailService: ItemDetailService,) {

  }

  async ngAfterViewInit() {
  }
  async onSelect(value) {
    return new Promise((res, rej) => {
      if (value != false) {
        this.selected = true
      } else {
        this.selected = false
      }
      res(this.selected);
    })

  }
  onSelectClosed() {
    this.selected = true
  }
  async ngOnInit() {

    console.log('Material')
    await this.itemService.getMerchGroup({ divisionCode: 1, DivisionStatus: 1 }).subscribe(async (data: any) => {
      console.log(data)
      for (var i = 0; data.length > i; i++) {
        for (var j = 0; data[i].merchGroupHeaders.length > j; j++) {
          if (data[i].merchGroupHeaders[j].mgTypeDesc == "Material") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.materialOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Construction") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.constructionOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Feature") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.featureOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Size") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.sizeOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Licensor") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.licensorOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Property") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.propertyOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Format") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.formatOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Art Content") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              this.artContentOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code })
            }
          } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Style guide") {
            for (var k = 0; data[i].merchGroupHeaders[j].merchGroups.length > k; k++) {
              //   this.groupOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code  })
            }
          }
        }
      }
    })



    let href = document.location.href.split('/')

    this.itemService.getUDFElements({ component: href[href.length - 2] }).subscribe(data => {
      console.log(data)

      data.forEach(element => {
        if (!this.UDFElementGroup.includes(element.UDFGroup.UDFGroup_name)) {
          this.UDFElementGroup.push(element.UDFGroup.UDFGroup_name)
        }
      });

      this.UDFElement = data;
    })



    await this.ItemDetailService.getItemInfo({ id: href[href.length - 1] }).subscribe(item => {
      this.itemData = item
      this.selectedMaterial = this.materialOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup01);
      this.selectedConstruction = this.constructionOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup02);
      this.selectedFeature = this.featureOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup03);
      this.selectedSize = this.sizeOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup04);
      this.selectedLicensor = this.licensorOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup05);
      this.selectedProperty = this.propertyOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup06);
      this.selectedFormat = this.formatOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup07);
      this.selectedArtContent = this.artContentOptions.find(x => x.mg_code == this.itemData[0].udf_merchgroup08);
      this.itemService.getMG({ division_name: item[0].compan_code_fk, company_name: item[0].div_code_fk }).subscribe(data => {
        this.mg = data;
        console.log(data)
      })
      console.log(this.itemData)
      this.dates.push(new FormControl(this.itemData[0].udf_date01));
      this.dates.push(new FormControl(this.itemData[0].udf_date02));
      this.dates.push(new FormControl(this.itemData[0].udf_date03));
      this.dates.push(new FormControl(this.itemData[0].udf_date05));
      this.dates.push(new FormControl(this.itemData[0].udf_date06));
      console.log(this.dates)
      item[0].itemAttachments.forEach(element => {
        if (item[0].itemAttachments) {
          if (element.attachment_display_name != 'Thumb' && element.attachment_display_name != 'Image') {
            this.attachments.push(element)
          } else if (element.attachment_display_name == 'Thumb') {
            this.detailThumbs.push(element)
          } else if (element.attachment_display_name == 'Image') {
            this.detailImages.push(element)
          }
        }
      });
      for (var i = 0; this.detailImages.length > i; i++) {
        this.GalleryItems.push(
          new ImageItem({ src: this.detailImages[i].attachment_link, thumb: this.detailThumbs[i].attachment_link })
        )
      }
      this.gallery.ref().load(this.GalleryItems);
      this.gallery.ref().itemClick.subscribe((index) => {
        this.dialog.open(imageEditorComponent, {
          data: {
            FullSizePicture: this.detailImages[index].attachment_link
          }
        });
      })
    })

    this.asyncTabs = [
      { id: '1', label: 'Main' },
      { id: '2', label: 'Licensing' },
      { id: '3', label: 'Pricing' },
      { id: '4', label: 'Production' },
      { id: '5', label: 'Item logs' },
      { id: '6', label: 'Item admin logs' },
    ]
  }

  onItem() {
    window.location.href = 'http://www.google.com'
  }
}

